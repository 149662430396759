/** @jsx jsx */
import { jsx, Text, Box, Flex } from 'theme-ui'
import Divider from '../../elements/divider'
import Inner from '../../elements/inner'
import Content from '../../elements/content'
import Svg from '../svg'
import { UpDown, UpDownWide } from '../../styles/animations'

export const History = ({ top, height }: { top: string; height: string }) => (
  <div>
    <Divider
      bg="tertiary"
      clipPath="polygon(0 15%, 100% 25%, 100% 85%, 0 75%)"
      sx={{
        top,
        height,
      }}
    />
    <Content sx={{ top, height }}>
      <Inner>
        <CompanyTimeline history={historyData} />
      </Inner>
    </Content>
    <Divider sx={{ top, height }}>
      <UpDown>
        <Svg icon="box" width={6} color="icon_brightest" left="85%" top="75%" />
        <Svg icon="upDown" width={8} color="icon_teal" left="70%" top="20%" />
        <Svg
          icon="triangle"
          width={8}
          stroke
          color="icon_orange"
          left="25%"
          top="5%"
        />
        <Svg
          icon="circle"
          hiddenMobile
          width={24}
          color="icon_brightest"
          left="17%"
          top="60%"
        />
      </UpDown>
      <UpDownWide>
        <Svg
          icon="arrowUp"
          hiddenMobile
          width={16}
          color="icon_green"
          left="20%"
          top="90%"
        />
        <Svg
          icon="triangle"
          width={12}
          stroke
          color="icon_brightest"
          left="90%"
          top="30%"
        />
        <Svg
          icon="circle"
          width={16}
          color="icon_yellow"
          left="70%"
          top="90%"
        />
        <Svg
          icon="triangle"
          hiddenMobile
          width={16}
          stroke
          color="icon_teal"
          left="18%"
          top="75%"
        />
        <Svg
          icon="circle"
          width={6}
          color="icon_brightest"
          left="75%"
          top="10%"
        />
        <Svg
          icon="upDown"
          hiddenMobile
          width={8}
          color="icon_green"
          left="45%"
          top="10%"
        />
      </UpDownWide>
      <Svg
        icon="circle"
        hiddenMobile
        width={6}
        color="icon_brightest"
        left="4%"
        top="20%"
      />
      <Svg icon="circle" width={12} color="icon_pink" left="80%" top="60%" />
      <Svg icon="box" width={6} color="icon_orange" left="10%" top="10%" />
      <Svg icon="box" width={12} color="icon_yellow" left="29%" top="26%" />
      <Svg
        icon="hexa"
        width={16}
        stroke
        color="icon_red"
        left="75%"
        top="30%"
      />
      <Svg
        icon="hexa"
        width={8}
        stroke
        color="icon_yellow"
        left="80%"
        top="70%"
      />
    </Divider>
  </div>
)
// CSS keyframes 애니메이션 정의
const fadeIn = {
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}

const TimelineItem = ({ year, content }) => (
  <Flex
    sx={{
      flexDirection: ['column', 'row'],
      alignItems: 'center',
      flexWrap: 'wrap',
      pb: [1, 1],
      animation: 'fadeIn 2s ease-out',
      ...fadeIn,
    }}
  >
    <Text
      sx={{
        fontWeight: 'bold',
        fontSize: [3, 4],
        mb: [2, 0], // Margin bottom on mobile only
        mr: [2, 2], // Margin bottom on mobile only
        color: 'text', // 글자색 변경
      }}
    >
      {year}
    </Text>
    <Box>
      <Text sx={{ fontSize: [2, 3], color: 'text' }}>{content}</Text>
    </Box>
  </Flex>
)

const CompanyTimeline = ({ history }) => (
  <Box
    sx={{
      width: '100%',
      overflowX: 'auto',
      px: [3, 4],
      py: [3, 4],
      color: 'white',
    }}
  >
    <Flex sx={{ flexDirection: 'column' }}>
      <Box sx={{ py: [3, 4] }}>
        <Text sx={{ fontSize: [4, 6], color: 'text', fontWeight: 'bold' }}>
          沿革
        </Text>
      </Box>
      {history.map((item, index) => (
        <TimelineItem key={index} year={item.year} content={item.content} />
      ))}
    </Flex>
  </Box>
)

const historyData = [
  { year: '2022', content: '株式会社 UKMETA 会社設立' },
  { year: '2022', content: '初期投資調達' },
  { year: '2023', content: 'クライアントとの契約締結' },
  { year: '2024', content: 'SES事業の開始' },
]
