/** @jsx jsx */
import { jsx } from 'theme-ui'
import Inner from '../../elements/inner'
import Content from '../../elements/content'
import Header from '../header'
import { Box } from 'theme-ui'

const HeroAboutUs = ({ height }: { height: number }) => (
  <div>
    <Content sx={{ variant: `texts.bigger`, top: 0, height: height }}>
      <Header />
      <Inner bottomPosition>
        <Box
          as="h1"
          sx={{
            fontSize: ['3rem', '4rem', '5rem'],
            fontWeight: 700,
            lineHeight: 1.25,
            letterSpacing: '0.025em',
            color: 'text',
          }}
        >
          Hello <br />
          We are UKMETA
        </Box>
      </Inner>
    </Content>
  </div>
)

export default HeroAboutUs
