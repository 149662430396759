import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`COMPANY`}</h2>
    <blockquote>
      <p parentName="blockquote">{`社　　名 株式会社 UKMETA`}<br />{`
取引銀行 住信 SBI ネット銀行`}<br />{`
事業内容 SES 事業、受託開発、アプリ開発、人材仲介 `}<br />{`
設立日 2022-03-02`}<br /></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      